<template>
  <div id="order-view-action-save-and-send">
    <b-button
      id="order-view-action-save-and-send"
      v-ripple.400
      variant="success"
      class="mb-75"
      block
      :disabled="disabled || quoteTouched || !quoteSelected.quote.paymentTerm || quoteSelected.quote.priceExcludingTaxes === 0"
      @click.prevent="saveAndSendEdition = true"
    >
      <feather-icon icon="SendIcon" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.save_and_send') }} </span>
    </b-button>
    <span v-if="quoteSelected.quote.priceExcludingTaxes === 0" class="text-danger">{{ $t('quote.save_and_send.cannot_send_quote_without_price') }}</span>
    <span v-if="!quoteSelected.quote.paymentTerm" class="text-danger">{{ $t('quote.save_and_send.cannot_send_quote_without_payment_term') }}</span>
    <p v-if="quoteTouched" class="text-danger">{{ $t('quote.save_and_send.cannot_send_when_editing') }}</p>

    <app-sidebar-form
      form-name="order-view-action-save-and-send"
      :edition="saveAndSendEdition"
      edition-mode-title="edit"
      :disabled="filesLoading || quoteSending"
      :title="btnText || $t('action.save_and_send')"
      :entity="quoteSelected.quote"
      :submit-btn-text="$t('action.save_and_send')"
      submit-btn-icon="SendIcon"
      submit-btn-variant="success"
      no-pristine
      @update:edition="saveAndSendEdition = $event"
      @update:entity="saveAndSend($event)"
    >
      <template #fields="data">
        <b-row>
          <div v-if="data.item.state === 'awaiting_for_quotation'" class="mb-1">
            <b-col cols="12">
              <span class="font-weight-bold text-nowrap">{{ `${$tc('payment.term.title', 2)} : ` }} </span>
              <span class="text-nowrap">{{ data.item.paymentTerm | enumTranslate('quote_payment_term') }} </span>
            </b-col>
            <b-col v-if="data.item.concretePaymentDate" cols="12">
              <span class="font-weight-bold text-nowrap">{{ `${$t('payment.term.specific_terms')} : ` }} </span>
              <span class="text-nowrap">
                {{
                  `${$t('payment.date.concrete_payment_at')} ${new Date(data.item.concretePaymentDate).toLocaleDateString($i18n.locale, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  })} `
                }}
              </span>
            </b-col>
          </div>
          <b-col cols="12">
            <!-- message -->
            <validation-provider #default="{ errors }" :name="$t('common.message')" rules="" vid="order-view-action-save-and-send-message-provider">
              <b-form-group :label="$t('common.message')" label-for="order-view-action-save-and-send-message">
                <b-form-textarea
                  id="order-view-action-save-and-send-message"
                  v-model="data.item.message"
                  :placeholder="$t('common.message')"
                  no-resize
                  rows="4"
                  class="hide-scroll"
                  max-rows="4"
                  :state="errors[0] ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- servicesDelivered -->
          <b-col v-if="data.item.state === 'awaiting_for_quotation'" cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('order.services_delivered.title')"
              rules=""
              vid="order-view-action-save-and-send-services-delivered-provider"
            >
              <b-form-group :label="$t('order.services_delivered.title')" label-for="order-view-action-save-and-send-services-delivered">
                <b-form-checkbox id="order-view-action-save-and-send-services-delivered" v-model="data.item.servicesDelivered">
                  {{ $t('order.services_delivered.text') }}
                </b-form-checkbox>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- files -->
          <b-col cols="12">
            <AppAxiosFormFile @updateId="data.item.filesIds = $event" @upload-in-progress="filesLoading = $event" />
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppAxiosFormFile from '@/components/AppAxiosFormFile.vue'

import { patchQuoteSaveAndSendRequest } from '@/request/globalApi/requests/quoteRequests'

export default {
  name: 'OrderViewActionSaveAndSend',

  components: {
    AppSidebarForm,
    AppAxiosFormFile,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    btnText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      saveAndSendEdition: false,
      filesLoading: false,
      quoteSending: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteTouched']),
    ...mapGetters('quote', ['quoteTitle']),
    ...mapFields('quote', ['quoteLoading', 'quoteEditable', 'validateQuote', 'quoteValid', 'quotePaymentTermsRequired']),
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    saveAndSend(quote) {
      this.quotePaymentTermsRequired = true
      this.validateQuote = true
      this.quoteSending = true
      setTimeout(() => {
        if (this.quoteValid) {
          this.quoteLoading = true
          if (quote.state === 'delivered' && this.quoteEditable) {
            return patchQuoteSaveAndSendRequest(
              { ...quote, showInvoiceUrl: window.location.href },
              this.$store.getters['quote/quoteTitleAndRef'],
            )
              .then(() => {
                this.quoteEditable = false
                this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
                  this.quotePaymentTermsRequired = false
                  this.quoteValid = false
                  this.quoteLoading = false
                })
              })
              .catch(err => {
                err && (this.quoteLoading = false) && (this.quoteValid = false) && (this.quotePaymentTermsRequired = false)
              })
              .finally(() => {
                this.quoteSending = false
              })
          }

          return patchQuoteSaveAndSendRequest({ ...quote, showInvoiceUrl: window.location.href }, this.$store.getters['quote/quoteTitleAndRef'])
            .then(() => {
              this.quoteEditable = false
              this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
                this.quotePaymentTermsRequired = false
                this.quoteValid = false
                this.quoteLoading = false
              })
            })
            .catch(err => {
              err && (this.quoteLoading = false) && (this.quoteValid = false) && (this.quotePaymentTermsRequired = false)
            })
            .finally(() => {
              this.quoteSending = false
            })
        }
        return null
      }, 100)

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
